import React from 'react';
import { navigate, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { ButtonPrimary, ButtonSecondary } from '../Buttons';
import { H1 } from '../Common';
import { COLORS } from '../../theme';
import MEDIA from '../../mediaTemplates';
import ls from 'local-storage';

const COOKIE_CONSENT = 'COOKIE_CONSENT';
const AGREE = 'COOKIE_CONSENT_AGREE';
const DISAGREE = 'COOKIE_CONSENT_DISAGREE';

const triggerIbe = room_id => {
  if (typeof window == 'undefined') return;
  if (!window.d21_api) {
    navigate('/contact');
    return;
  }

  window.d21_api.ibe.open({ room_id });
};

export default ({ email, priceSingle, priceDouble }) => {
  let USER_COOKIE_CONSENT = false;

  if (typeof window !== 'undefined') {
    USER_COOKIE_CONSENT = ls(COOKIE_CONSENT);
  }

  const setConsentAgree = () => {
    if (typeof window == 'undefined') return;
    ls(COOKIE_CONSENT, AGREE);
    window.location.reload();
  };

  const setConsentDisagree = () => {
    if (typeof window == 'undefined') return;
    ls(COOKIE_CONSENT, DISAGREE);
    window.location.reload();
  };

  return (
    <Container>
      {USER_COOKIE_CONSENT === AGREE ? (
        <Helmet>
          <script
            src="https://js-sdk.dirs21.de/daaad2bf-0529-42de-9ead-0b3d00e805ec"
            type="text/javascript"
            async
          />
        </Helmet>
      ) : null}

      {USER_COOKIE_CONSENT !== DISAGREE ? (
        <Options>
          {!USER_COOKIE_CONSENT ? (
            <Consent>
              <div>
                <p>
                  Um unser Buchungstool benutzen zu können, ist eine Zustimmung
                  zu den in der{' '}
                  <Link to="/datenschutz">
                    Datenschutzerklärung
                  </Link>{' '}
                  genannten Drittanbietern und optionalen Cookies erforderlich.
                </p>
                <ButtonPrimary transition={false} onClick={setConsentAgree}>
                  Zustimmen
                </ButtonPrimary>
                <ButtonSecondary hideBorder={true} onClick={setConsentDisagree}>
                  Ablehnen
                </ButtonSecondary>
              </div>
            </Consent>
          ) : null}
          <Option onClick={() => triggerIbe(116480)}>
            <H1 as="h4">Einzelzimmer</H1>
            <div>ab {priceSingle}€</div>
          </Option>
          <Option onClick={() => triggerIbe(116316)}>
            <H1 as="h4">Doppelzimmer</H1>
            <div>ab {priceDouble}€</div>
          </Option>
        </Options>
      ) : null}

      <Backup>
        <p>Oder senden Sie uns eine Email:</p>
        <ButtonSecondary as="a" href={`mailto:${email}`}>
          {email}
        </ButtonSecondary>

        <p>
          Bei Zimmerbuchung beachten Sie bitte die jeweils gültigen{' '}
          <a
            href="https://www.schlosshotel-gedern.de/hotel-restaurant/zimmer/"
            target="_blank"
          >
            Coronaschutzverordnungen
          </a>
          .
        </p>
      </Backup>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 80px;
`;

const Consent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 48, 47, 0.7);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  & > div {
    max-width: 450px;
  }

  p {
    margin-bottom: 2rem;
  }

  a {
    color: inherit;
  }

  ${ButtonSecondary} {
    margin-left: 2rem;
  }
`;

const Options = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  padding: 0 2rem;
  position: relative;

  ${MEDIA.PHONE`
    grid-template-columns: 1fr;
  `};
`;

const Option = styled.button`
  height: 50vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  font: inherit;
  border: 1px solid rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border-radius: 2px;
  transition: 0.2s background-color ease;

  ${MEDIA.PHONE`
    height: 25vh;
    & + & {
      margin-top: 2rem;
    }
  `};

  & > div {
    margin-top: -1rem;
    color: ${COLORS.TEXT_TERTIARY};
  }

  &:active,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.12);
    outline: none;
  }

  &:hover {
    background-color: ${COLORS.BACKGROUND_DIMMED};
  }
`;

const Backup = styled.div`
  margin-top: 80px;
  text-align: center;
  opacity: 0.5;
  transition: 0.2s opacity ease;

  &:hover {
    opacity: 1;
  }

  & > p:last-of-type {
    font-size: 14px;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 640px;
    margin: 48px auto 0;

    ${MEDIA.PHONE`
    margin: 48px 2rem 0;
  `};

    a {
      color: inherit;
    }
  }
`;
