import React from 'react';
import { graphql } from 'gatsby';

import Head from '../components/Head';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Rooms from '../components/Rooms';

import GlobalStyle from '../GlobalStyle';

export default ({ data }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Head pageTitle="Zimmeranfrage" pageUrl="/rooms" />
      <Header absolute />

      <Rooms
        email={data.prismicHomepage.data.email.text}
        priceSingle={data.prismicHomepage.data.price_single}
        priceDouble={data.prismicHomepage.data.price_double}
      />

      <Footer
        image={data.prismicHomepage.data.outro}
        address={data.prismicHomepage.data.address.html}
      />
      <GlobalStyle />
    </div>
  );
};

export const pageQuery = graphql`
  {
    prismicHomepage {
      data {
        address {
          html
        }
        price_single
        price_double
        outro {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        email {
          text
        }
      }
    }
  }
`;
